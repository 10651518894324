import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { v4 as uuidv4 } from "uuid";
import { Container } from "components/style";
import PriceRangeModal from "./modal";
import * as SC from "./style";

const CoursePriceRange = ({
  formPostUrl,
  formId,
  priceRange = [],
  courseTitle,
  disableTooltips,
  closed,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [chosenPrice, setChosenPrice] = useState("");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <SC.PriceRangeSection>
      <Container>
        <Modal
          center
          closeIcon={<div />}
          open={modalIsOpen}
          onClose={closeModal}
          focusTrapped={false}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
        >
          <PriceRangeModal
            priceRange={priceRange}
            courseTitle={courseTitle}
            formId={formId}
            formPostUrl={formPostUrl}
            chosenPrice={chosenPrice}
            openModal={openModal}
            closeModal={closeModal}
            modalIsOpen={modalIsOpen}
          />
        </Modal>

        <SC.PriceRangeWrapper pricesNumber={priceRange.length}>
          {priceRange.map(({ pricesTitle, priceConditions, price }) => (
            <SC.PriceCategory key={uuidv4()}>
              <SC.PriceCategoryHeader>
                <SC.PriceCategoryPackage id="price-category-package">
                  Пакет
                </SC.PriceCategoryPackage>
                <SC.PriceCategoryTitle>{pricesTitle}</SC.PriceCategoryTitle>
              </SC.PriceCategoryHeader>
              <SC.PriceCategoryBody>
                <SC.PriceCategoryList
                  disableTooltips={disableTooltips}
                  className="price-category-list"
                >
                  <ul>
                    {priceConditions.map(({ text, isActive, description }) => (
                      <li key={uuidv4()}>
                        <p>
                          {isActive ? <b>{text}</b> : text}
                          {description && !disableTooltips && (
                            <>
                              {isActive ? (
                                <b>
                                  <i>{description}</i>
                                </b>
                              ) : (
                                <i>{description}</i>
                              )}
                            </>
                          )}
                        </p>
                      </li>
                    ))}
                  </ul>
                </SC.PriceCategoryList>
                <SC.PriceCategoryFooter>
                  <SC.PriceCategoryButton
                    closed={closed}
                    onClick={() => {
                      if (!closed) {
                        openModal();
                        setChosenPrice(price);
                      }
                    }}
                  >
                    {closed ? "Закрыт" : `${price} ₽`}
                  </SC.PriceCategoryButton>
                  <SC.PriceCategorySubtext id="price-category-subtext">
                    ∞ Бессрочный доступ
                  </SC.PriceCategorySubtext>
                </SC.PriceCategoryFooter>
              </SC.PriceCategoryBody>
            </SC.PriceCategory>
          ))}
        </SC.PriceRangeWrapper>
      </Container>
    </SC.PriceRangeSection>
  );
};

export default CoursePriceRange;
